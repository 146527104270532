.rd-plan {
}
.rd-plan-info {
  background-color: azure;
  border: 1px solid #ebebeb;
  padding: 0px 15px 25px 15px;
  .item {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    div {
      p {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.4px;
        color: #888888;
      }
      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        margin-top: 3px;
        letter-spacing: 0.4px;
        color: #333333;
      }
    }
  }
}
