.chat-screen {
  display: flex;
  margin: -12px;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.chat-screen-user-list {
  flex: 1.2;

  .header {
    border-right: 1px solid #dadada;
    background-color: #efefef;
    height: 59px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    justify-content: space-between;
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border-radius: 50%;
    }
    .more {
      font-size: 22px;
    }
  }
  .chat-search {
    height: 49px;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;

    .search {
      margin: 0 10px;
      background-color: white;
      border-radius: 18px;
      width: 100%;
      display: flex;
      justify-content: center;
      .icon-container {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .search-icon {
        font-size: 15px;
      }
      .base-input {
        font-size: 15px;
        height: 35px;
        width: 70%;
      }
    }
  }
}

.chat-screen-user-list-filter-bar {
  height: 40px;
  background-color: #f6f6f6;

  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  .unread-filter {
    display: flex;
    align-items: center;
    .icon {
      color: gray;
      margin-right: 10px;
      font-size: 20px;
    }
  }
}

.chat-screen-user-list-cards-container {
  background-color: white;
  //64+59+49+40+24
  $height: 236px;
  overflow-y: auto;
  height: calc(100vh - #{$height});
}

.chat-screen-user-list-card {
  cursor: pointer;
  height: 72px;
  display: flex;
  align-items: center;
  .user-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 13px;
    img {
      width: 49px;
      height: 49px;
      object-fit: contain;
      border-radius: 50%;
    }
  }
  .right {
    border-top: 1px solid #ebebeb;
    height: 100%;
    flex-grow: 1;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      align-items: center;
      display: flex;
      justify-content: space-between;
      h1 {
        font-size: 17px;
        font-weight: 400;
        color: #000000;
      }
      p {
        color: #b3b3b3;
        font-size: 12px;
      }
    }
    .buttom {
      display: flex;
      justify-content: space-between;
      p {
        color: #ababab;
        font-size: 14px;
      }
      .bedge {
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #00af9c;
        p {
          margin: 0;
          padding: 0;
          font-size: 10px;
          color: white;
        }
      }
    }
  }
}

.chat-screen-user-list-card:hover {
  background-color: #f5f5f5;
}

.chat-screen-user-list-card:first-child {
  .right {
    border-top: none;
  }
}

#selected-user-card {
  background-color: #ebebeb;
}

.chat-screen-panel {
  flex: 3;
  background-color: #ffffff;
  .header {
    background-color: #efefef;
    height: 59px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 50%;
      }
      h1 {
        margin-left: 15px;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .more {
      font-size: 22px;
    }
  }
}

.message-bar {
  height: 62px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  .icon-container {
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .attach-icon {
    color: #f4f5f7;
    font-size: 18px;
  }
  .send-icon {
    font-size: 25px;
    color: #313131;
  }
  .base-input {
    width: 100%;
    background-color: #ffffff;
    height: 42px;
    border-radius: 21px;
    padding: 0 20px;
    font-size: 15px;
  }
}

.chat-screen-panel-container {
  background-color: lightgray;
  $height: 209px;
  overflow-y: auto;
  height: calc(100vh - #{$height});
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  .left-text {
    margin-top: 10px;
    background-color: #ffffff;
    width: 40%;
    padding: 10px;
    border-radius: 12px;
    margin-left: 15px;
    p {
      font-size: 14.2px;
      color: #303030;
      font-weight: 500;
    }
    .time {
      display: flex;
      justify-content: flex-end;
      p {
        // margin-right: 10px;
        color: #303030;
        font-weight: 500;
        font-size: 11px;
      }
    }
  }

  .right-text {
    align-self: flex-end;
    @extend .left-text;
    background-color: #dcf8c6;
    margin-left: 0px;
    margin-right: 15px;
  }

  .new-msg-bar {
    background-color: #ebebeb;
    opacity: 0.6;
  }

  .read-msg-tick {
    margin-left: 5px;
    color: #34afe6;
    font-size: 18px;
  }

  .delivered-msg-tick {
    margin-left: 5px;
    font-size: 18px;
  }
  .single-msg-tick {
    margin-left: 5px;
    font-size: 18px;
  }
}

.empty-chat-panel {
  background-color: #262d31;
  flex: 3;
}
