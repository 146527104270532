.custom-radio-group {
  //   background-color: aqua;
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
  //   .MuiRadio-root {
  //     // color: red;
  //     font-size: 5px;
  //   }
}
