.rd-issues {
  padding: 15px;
  height: 90vh;
  overflow-y: scroll;
  .topbar {
    display: flex;
    h1 {
      font-size: 24px;
      line-height: 28px;
      text-transform: capitalize;
      color: #333333;
    }
  }
  .card-container {
    margin-top: 30px;
    border-top: 1px solid #ebebeb;
  }
}

.issue-card {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;

  h2 {
    margin-left: 30px;
    font-size: 13px;
    color: #888888;
  }
  .div1 {
    // flex-grow: 1;
    display: flex;
    // justify-content: space-between;
    h1 {
      font-size: 14px;
      color: #333333;
      font-weight: 600;
    }
  }
  .div2 {
    p {
      padding: 2px 10px;
      border-radius: 10px;
      font-size: 12px;
    }
  }
}
