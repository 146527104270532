.rd-details {
  //   background-color: aqua;
  height: 90vh;
  padding: 15px;
  .topbar {
    display: flex;
    h1 {
      font-size: 24px;
      line-height: 28px;
      text-transform: capitalize;
      color: #333333;
      margin-right: 30px;
    }
  }
  .info {
    display: flex;
    margin-top: 20px;
    .img {
      width: 200px;
      height: 180px;
      background: linear-gradient(
        112.34deg,
        rgba(98, 0, 238, 0.08) 8.98%,
        rgba(74, 198, 252, 0.0136) 131.73%
      );
      border: 1px solid rgba(98, 0, 238, 0.24);
      border-radius: 12px;
    }
    .status {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      img {
        padding-right: 20px;
      }

      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #888888;
      }
    }
  }
  .live-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #888888;
    }
  }
  .business-info-bar {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    div {
      flex: 1;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.4px;
      color: #888888;
    }
    h1 {
      padding-top: 2px;
      font-size: 16px;
      letter-spacing: 0.4px;
      color: #333333;
    }
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  .tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 27px;
    font-size: 15px;
    margin: 0;
    font-weight: 600;
    padding: 0px 10px;
    margin-bottom: 5px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #333333;
    p {
      padding-right: 10px;
    }
  }
}
