.rd-followup {
  background-color: #ffffff;
  padding: 15px;
  height: 90vh;
  overflow-y: scroll;
  .topbar {
    display: flex;
    align-items: center;
    h1 {
      font-size: 24px;
      line-height: 28px;
      text-transform: capitalize;
      color: #333333;
    }
    .MuiButton-contained {
      margin-left: 30px;
    }
  }
  .card-container {
    // height: 90vh;
    // overflow-y: auto;
  }
}

.rd-followup-card {
  background: linear-gradient(
    112.34deg,
    rgba(98, 0, 238, 0.08) 8.98%,
    rgba(74, 198, 252, 0.0136) 131.73%
  );
  border: 1px solid rgba(98, 0, 238, 0.24);
  border-radius: 12px;
  margin-top: 30px;
  padding: 16px;
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }
  .contianer {
    display: flex;
    padding-bottom: 20px;
    .div1 {
      width: 30%;
      // width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      img {
        width: 60px;
        height: 60px;
        padding-bottom: 10px;
      }
    }

    .div2 {
      flex-grow: 1;
      // background-color: aqua;
      .data {
        // background-color: azure;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #8b9ca8;
          span {
            color: #333333;
            font-weight: 500;
          }
        }
      }
      .text {
        margin-top: 25px;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #888888;
      }
    }
  }
}

.add-followup-dialog {
  padding: 30px;
  padding-top: 20px;

  h1 {
    color: #333333;
    padding-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
  form {
    display: flex;
    flex-direction: column;
  }
}
