.leadDrawer {
  min-width: 400px;
  width: 40vw;
  padding: 0px 20px 20px 20px;
}

.follow_message_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.follow_message_container p {
  margin-top: 0px;
  margin-right: 10px;
  background-color: lightblue;
  width: 170px;
  line-height: 30px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
}
