.rd-messenger {
  background-color: #ffffff;
  padding: 15px;
  .topbar {
    display: flex;
    align-items: center;
    h1 {
      font-size: 24px;
      line-height: 28px;
      text-transform: capitalize;
      color: #333333;
    }

    p {
      margin-left: 10px;
      border-radius: 22px;
      padding: 0 10px;
      font-size: 15px;
    }
    .MuiButton-contained {
      margin-left: auto;
    }
  }

  .msg-container {
    display: flex;
    flex-direction: column;
    height: 70vh;
    padding-bottom: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .team-message {
    width: 80%;
    margin: 10px 0px;
    display: flex;
    .user-icon {
      margin-right: 10px;
    }

    .msg {
      background-color: #fafafa;
      padding: 10px;
      border-radius: 0px 16px 16px 16px;

      h1 {
        font-size: 10px;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #8b9ca8;
      }
    }
  }
  .user-message {
    align-self: flex-end;
    width: 70%;
    margin: 10px 0px;
    display: flex;
    flex-direction: row-reverse;
    .user-icon {
      margin-left: 10px;
    }

    .msg {
      background: #f6f6f8;
      border-radius: 16px 0px 16px 16px;
      padding: 10px;

      h1 {
        font-size: 10px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #374752;
      }
    }
  }

  .chat-bar {
    // position: fixed;
    // bottom: 10px;
    width: "40vw";
    // background-color: rebeccapurple;
  }
}
