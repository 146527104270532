.rd-documents {
  // background-color: aqua;
  padding: 15px;
  .topbar {
    display: flex;
    align-items: center;
    h1 {
      font-size: 24px;
      line-height: 28px;
      text-transform: capitalize;
      color: #333333;
    }

    .MuiButton-contained {
      margin-left: 30px;
    }
  }

  .payment-method {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    div {
      flex: 1;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.4px;
      color: #888888;
    }
    h1 {
      padding-top: 2px;
      font-size: 16px;
      letter-spacing: 0.4px;
      color: #333333;
    }
  }

  .doclist {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;

    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-transform: capitalize;
      color: #333333;
      margin-top: 5px;
      text-align: center;
    }
    .doc {
      width: 23%;
      div {
        border-radius: 12px;
        border: 1px solid #ebebeb;
        height: 106px;

        img {
          width: 100%;
          height: 100%;
          border-radius: inherit;
          object-fit: contain;
        }
      }
    }
  }
  .btn-bar {
    position: absolute;
    bottom: 15px;
    width: 95%;
    // background-color: lawngreen;
    div {
      display: flex;
      justify-content: space-evenly;
      .MuiButton-contained {
        width: 40%;
      }
      .MuiButton-outlined {
        width: 40%;
      }
    }
  }
}
