.card {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  padding: 15px 30px;
  margin-bottom: 40px;
}

.subtitle {
  margin: 0px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #979797;
}

.subtitle span {
  color: #333333;
}
.name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
