@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.drawer {
  width: 80vw;
  display: flex;
  font-family: "Montserrat", sans-serif;
  padding: 40px;
  padding-top: 10px;
}

.subheading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  line-height: 17px;
  color: #979797;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  margin-top: 6px;
  line-height: 20px;
  color: #333333;
}
.s1 {
  width: 50%;
  margin-right: 20px;
}
.s1_header {
  display: flex;
  justify-content: space-between;
}

.s1_title {
  font-family: "Lora", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  margin: 0;
  color: #333333;
}

.auto-asign {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #888888;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 0px 10px;
  height: 27px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 10px;
  line-height: 17px;
  color: #333333;
}

.hrline {
  border: 1px solid #ebebeb;
  margin: 24px 0px;
}

.s2 {
  width: 50%;
  padding: 24px;
  background: #fafafa;
  margin-left: 20px;
}

.s2_title {
  font-weight: 600;
  font-size: 28px;
  color: #3554b9;
}

.subheading2 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  line-height: 22px;
  color: rgba(25, 44, 56, 0.87);
  opacity: 0.5;
}
.subtitle2 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  line-height: 22px;
  color: #374752;
}
.line-dotted {
  opacity: 0.5;
  border: 1px dashed #707070;
  margin: 25px 0px;
}

.item-div2 {
  display: flex;
  justify-content: space-between;
}
.item-div2 p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;

  margin: 0px;
  color: rgba(25, 44, 56, 0.87);
}
.item-div2 span {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #112c4d;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  background-color: white;
  width: 457px;
  padding: 40px 24px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  border-radius: 12px;
}
.paper h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.paper button {
  margin-top: 40px;
  background: #2d9cdb;
  border-radius: 4px;
  width: 240px;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  align-self: center;
}

.delivery-partner {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
/* .delivery-partner img {
  height: 64px;
  width: 88px;
  object-fit: contain;
  padding: 0px 10px;
} */

.delivery-partner-img {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
