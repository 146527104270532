.pipeline-header {
  margin-top: -10px;
  margin-bottom: 20px;
  .div1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .div1-img {
      display: flex;
      align-items: center;
    }
  }
  .div2 {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
    }
  }
  .div3 {
    display: flex;
    justify-content: flex-start;
  }
}

.pipeline-container {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  // overflow-y: scroll;
}

.pipeline-card {
  padding: 12px;
  .name {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }

    img:nth-child(1) {
      padding-right: 10px;
    }
    img:nth-child(3) {
      padding-left: 10px;
    }
  }

  .tags {
    p {
      margin-top: 5px;
      border-radius: 10px;
      text-align: center;
      font-size: 12px;
      padding: 2px 0px;
    }
  }
  .date {
    display: flex;
    align-items: center;
    margin-top: 7px;
    p {
      margin-left: 10px;
      font-size: 12px;
      color: #888888;
    }
  }
  .assing_to {
    margin-top: 5px;
    h1 {
      color: #333333;
      font-size: 12px;
    }
  }
}
