.rd-activity {
  // background-color: aqua;
  height: 90vh;
  .topbar {
    display: flex;
    justify-content: space-between;
    padding: 19px 19px 40px 19px;
    h1 {
      font-size: 24px;
      line-height: 28px;
      text-transform: capitalize;
      color: #333333;
    }
    // .MuiButton-contained {
    //   margin-left: auto;
    // }
  }
  .title-bar {
    display: flex;
    height: 43px;
    align-items: center;
    background: #fafafa;
    justify-content: space-around;
    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: #000000;
    }
  }
}

.rd-activity-card {
  padding-top: 10px;
  padding-bottom: 31px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-around;
  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #333333;
    }
  }

  .div1 {
    padding-left: 10px;
    // background-color: cadetblue;
    width: 35%;
    display: flex;
    align-items: center;
    .name {
      padding-left: 10px;
    }
  }
}
