.lead-header {
  margin-top: -10px;
  margin-bottom: 20px;
  .div1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .div1-img {
      display: flex;
      align-items: center;
    }
  }
  .div2 {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
    }
  }
  .div3 {
    display: flex;
    justify-content: flex-start;
  }
}
