.theme-header {
  margin-top: -10px;
  margin-bottom: 20px;

  .div1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .div1-img {
      display: flex;
      align-items: center;
    }
  }
  .div2 {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
    }
  }
}

.theme-card-container {
  width: 835px;

  .cta-container {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    .MuiButton-root {
      margin-left: 30px;
      text-align: center;
    }
  }
}

.theme-card {
  background-color: white;
  display: flex;
  padding: 10px;
  margin: 20px 0;

  img {
    width: 131px;
    height: 78px;
    cursor: pointer;
  }
  .content {
    padding: 0 10px;
    width: 692px;
    display: flex;
    justify-content: space-between;

    .div1,
    .div2 {
      width: 35%;
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
      }
      p:last-child {
        margin-top: 10px;
      }
    }

    .color-box {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .edit-icon {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 30px;
        img {
          height: 30px;
          width: 30px;
        }
      }
      .color-group {
        display: flex;
        align-items: center;

        .divider {
          background-color: #979797;
          width: 1px;
          height: 27px;
          margin-right: 8px;
        }
        .color-circle {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

.theme-drawer {
  //

  background-color: #fafafa;
  height: auto;
  width: 75vw;
  display: flex;
  .phone-box {
    display: flex;
    width: 65%;
    background-color: white;
    height: 80vh;
    padding-top: 20px;
    padding-left: 40px;
    margin: 0px 0 0 0px;
    // overflow: scroll;

    .smartphone {
      position: relative;
      width: 344px;
      height: 580px;
      margin: auto;
      border: 16px #ffffff solid;
      border-top-width: 60px;
      border-bottom-width: 60px;
      border-radius: 36px;
      box-shadow: pink 0px 0px 0px 2px, pink 0px 4px 6px -1px,
        rgb(250, 250, 210) 0px 1px 0px inset;
    }

    /* The horizontal line on the top of the device */
    .smartphone:before {
      content: "";
      display: block;
      width: 60px;
      height: 5px;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #333;
      border-radius: 10px;
    }

    /* The circle on the bottom of the device */
    .smartphone:after {
      content: "";
      display: block;
      width: 35px;
      height: 35px;
      position: absolute;
      left: 50%;
      bottom: -65px;
      transform: translate(-50%, -50%);
      background: #333;
      border-radius: 50%;
    }

    /* The screen (or content) of the device */
    .smartphone .content {
      width: 312px;
      height: 455px;
      border: 1px solid #333333;
      //   height: 450px;
      background: white;
    }

    .color-box {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .MuiList-padding {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }

      .MuiList-root,
      .MuiMenu-list,
      .MuiList-padding {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding: 0;
      }

      .color-card {
        margin-right: 40px;
        display: flex;
        align-items: center;
        height: 40px;
        div {
          width: 15px;
          height: 15px;
          border-radius: 50%;
        }
        p {
          margin-left: 13px;
          font-size: 14px;
          line-height: 19px;
          color: #333333;
        }
        img {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .right-box {
    flex-grow: 1;

    .right-drawer-filter {
      display: flex;
      padding: 0 30px;
      justify-content: space-between;
    }

    .close-icon {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
    }

    .input-container {
      //   background-color: khaki;
      padding: 0 30px;
      display: flex;
      flex-direction: column;

      input {
        margin-top: 35px;
        outline: none;
        background-color: inherit;
        height: 40px;
        border: none;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.04em;
        color: #000000;
        border-bottom: 1px solid #333333;
      }
    }

    .image-upload {
      margin-top: 35px;
      padding: 0 30px;
      p {
        font-weight: 600;
        font-size: 18px;
        color: #5c5c5c;
      }

      .image-upload-box {
        margin-top: 5px;
        height: 39px;
        border: 1px dashed #333333;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
        border-radius: 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          //   line-height: 20px;
          cursor: pointer;
          span {
            text-decoration-line: underline;
            color: #3377ff;
          }
        }
      }
    }

    .uploaded-img {
      margin-top: 20px;
      padding: 0 30px;
      height: 150px;
      display: flex;
      justify-content: center;
      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }
    .cta-container {
      margin: 30px 0;
      padding: 0 30px;
      display: flex;
      justify-content: flex-end;
      .MuiButton-root {
        width: 155px;
        height: 30px;
        text-align: center;
      }
    }
  }
}
