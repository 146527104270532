.conversion-header {
  margin-top: -5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .div1 {
    display: flex;
  }
  .div2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
