.custom-pegination {
  position: fixed;
  bottom: 0;
  width: 78vw;
  display: flex;
  margin-top: 5px;
  align-items: center;
  background-color: #fafafa;
  padding-top: 5px;
  justify-content: space-between;

  .txt {
    h1 {
      font-weight: normal;
      font-size: 18px;
      color: #888888;
      span {
        color: #333333;
      }
    }
  }
  .MuiPagination-root {
    display: block;
  }
}
