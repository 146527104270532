.info-card {
  background-color: white;
  width: 20%;
  padding: 10px;
  min-width: 140px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-left: 10px;
    color: #787878;
  }
  div {
    margin-top: 5px;
    display: flex;
    align-items: center;
    h1 {
      font-weight: 600;
      margin-left: 23px;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.02em;
      color: #333333;
    }
  }
}
